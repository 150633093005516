import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><span style={{
        "color": "MediumPurple"
      }}>{`Notable Projects`}</span></h2>
    <ProjectCard title="Sygnia" link="https://www.sygnia.co.za/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
FinTech company based in South Africa.
      <p style={{
        "color": "#2d3748"
      }}>
  <b>My role:</b> Transform the design proposal into pixel perfect accesible and
  responsive React library components as well as set up HighCharts.
      </p>
    </ProjectCard>
    <ProjectCard title="Vanguard" link="https://investor.vanguard.com/corporate-portal/" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  The largest American registered investment advisor.
  <p style={{
        "color": "#2d3748"
      }}>
    <b>My role:</b> Turn UI related feedback into pixel perfect, accessible and
    responsive React library components for further development.
  </p>
    </ProjectCard>
    <ProjectCard title="OP Bank" link="https://www.op.fi/" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Finnish Bank, that helps to find the perfect investment strategy for their
  clients.
  <p style={{
        "color": "#2d3748"
      }}>
    <b>My role:</b> As a part of a team, I transformed the design proposal into
    pixel perfect accesible and responsive React library components as well as
    set up HighCharts.
  </p>
    </ProjectCard>
    <ProjectCard title="OMW" link="https://platform.quilter.com/" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  FinTech company Old Mutual Wealth based in South Africa.
  <p style={{
        "color": "#2d3748"
      }}>
    <b>My role:</b> My team and I worked on an accessibility fixes.
  </p>
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      