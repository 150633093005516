import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`A few words about me`}</h2>
    <p>{`Based in Portland, OR | Originally from Brno, Czech Republic`}</p>
    <p>{`I moved to Portland in 2016 to pursue new adventures and opportunities. Two years later my `}<a href="https://www.christyleezilka.com" target="_blank">{`wife`}</a>{` and I returned to Brno,
where I gained most of my experience as a web developer working for FinTech company `}<a href="https://www.fnz.com" target="_blank">{`FNZ`}</a>{`
. Now I am back in Portland and seeking an opportunity that matches my skillset and
career aspirations.`}</p>
    <p>{`Check out my `}<a href="https://github.com/blahutkam" target="_blank">{`Github`}</a>{`, `}<a href="https://codepen.io/blahec" target="_blank">{`Codepen`}</a>{` and few selected `}<a href="https://docs.google.com/presentation/d/1RaVHj76hvzhozRk_tW0CHnJZ3nM5aBEhjJmgLx2UAfo/edit?usp=sharing" target="_blank">{`design projects`}</a>{`
or download my `}<a href="https://drive.google.com/file/d/1HIq-JtFQj7ERZRaiuoC0xe0G-Cqef21B/view?usp=sharing" target="\_blank">{`resume`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      