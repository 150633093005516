import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Want to get in touch?`}</h2>
    <p>{`Try `}<a parentName="p" {...{
        "href": "mailto:blahutkam@gmail.com"
      }}>{`blahutkam@gmail.com`}</a>{` or find me on `}<a href="https://www.linkedin.com/in/martin-blahutka/" target="_blank">{`
LinkedIn`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      