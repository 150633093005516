import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Ahoy!`}</h2>
    <p>{`With my diverse background and more than 10 years of experience in IT & multimedia, I am an exceptionally
skilled web developer, designer & creative thinker with a passion for solving technological problems and building responsive, interactive & accessible digital platforms.`}</p>
    <p><strong parentName="p">{` Technologies I enjoy working with: `}</strong></p>
    <p style={{
      "fontSize": "24px"
    }}>
  <span style={{
        "color": "khaki"
      }}> Drupal </span> |{" "}
  <span style={{
        "color": "khaki"
      }}> GatsbyJS </span> |{" "}
  <span style={{
        "color": "khaki"
      }}>MERN stack</span> |{" "}
  <span style={{
        "color": "khaki"
      }}> Sharepoint</span>
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      